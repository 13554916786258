import React from 'react'
import Layout from '../components/Layout'
import Banner from '../components/Banner'
import WhatWeDo from '../components/WhatWeDo'
import WhatOurClientsSay from '../components/WhatOurClientsSay'
import { StaticQuery, graphql } from 'gatsby';

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query SiteFeaturesEnabledQuery {
        site {
          siteMetadata {
            featureToggles {
              testimonials
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Banner />
        <WhatWeDo />
        {
          data && data.site.siteMetadata.featureToggles.testimonials &&
          <WhatOurClientsSay />
        }
      </Layout>
    )}
  />
)

export default IndexPage
