import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Card, CardBody, CardText, CardTitle } from 'reactstrap'

const Feature = ({ icon, title, description }) => (
  <Card>
    <FontAwesomeIcon className="align-self-center" icon={icon} size="4x" />
    <CardBody>
      <CardTitle tag="h3">{title}</CardTitle>
      <CardText>{description}</CardText>
    </CardBody>
  </Card>
)

export default Feature
