import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Testimonial from './Testimonial'

const WhatClientsSay = () => (
  <StaticQuery
    query={graphql`
      query {
        allTestimonialsJson(
          filter: { approved: { eq: true } }
        ) {
          edges {
            node {
              id,
              image {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              },
              quote,
              companyName,
              author
            }
          }
        }
      }
    `}
    render={(data) => {
      if (data.allTestimonialsJson && data.allTestimonialsJson.edges.length) {
        return (
          <section id="what-our-clients-say" className="container">
            <header>
              <h2>What Our Clients Say</h2>
              <p>The team has over 20 years of experience in delivering successful projects for our clients.</p>
            </header>
            <div className="d-flex flex-column flex-wrap justify-content-center">
              {
                data.allTestimonialsJson.edges.map(testimonial =>
                  <Testimonial key={testimonial.node.id} image={testimonial.node.image && testimonial.node.image.childImageSharp.fluid.src} companyName={testimonial.node.companyName} author={testimonial.node.author} quote={testimonial.node.quote} />
                )
              }
            </div>
          </section>
        )
      } else {
        return null
      }
    }}
  />
)

export default WhatClientsSay
