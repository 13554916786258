import React from 'react'
import { Jumbotron } from 'reactstrap'
import DeadlyByteLogo from '../images/deadly-byte-symbol.svg'

const Banner = () => (
  <Jumbotron className="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-md-center">
    <img className="logo align-self-md-start" alt="Skull shaped as a usb key, with teeth as the connection points - Deadly Byte logo" src={DeadlyByteLogo} />
    <div className="intro ml-md-5 text-center text-md-left mr-md-5">
      <h1 className="display-4">Hi, we're <strong>Deadly Byte</strong>.</h1>
      <p>
        Deadly Byte is a web development studio based in Dorset, UK. We're passionate about building websites and web applications. If you're looking for quality coding that delivers exceptional results for your business then get in touch.
      </p>
      <a href="#what-we-do" className="btn btn-outline-primary btn-lg">Learn about what we do</a>
    </div>
  </Jumbotron>
)

export default Banner
