import React from 'react'
import { Card, CardImg, CardBody } from 'reactstrap'

const Testimonial = ({ image, quote, author, companyName }) => (
  <Card>
    {
      image &&
      <CardImg className="img-fluid" src={image} alt={`${companyName} logo`} width="130" />
    }
    <CardBody>
      <blockquote className="blockquote mb-0 text-center">
        <p>{quote}</p>
        <footer className="blockquote-footer">
          {author}, <cite title={`${author}, ${companyName}`}>{companyName}</cite>
        </footer>
      </blockquote>
    </CardBody>
  </Card>
)

export default Testimonial
