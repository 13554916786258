import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import Feature from './Feature'

const WhatWeDo = () => (
  <section id="what-we-do" className="container-fluid">
    <div className="container">
      <header>
        <h2>What We Do</h2>
        <p>We offer front end to back end web development services. We also offer web analytics, copywriting and media relations.</p>
        <p>Using the latest web technologies, we provide the solutions that help businesses achieve their online objectives.</p>
      </header>
      <StaticQuery
        query={graphql`
          query {
            allFeaturesJson(
              filter: { enabled: { eq: true } }
            ) {
              edges {
                node {
                  id,
                  icon,
                  title,
                  description
                }
              }
            }
          }`
        }
        render={(data) => (
          <div className="features d-flex flex-column flex-md-row flex-md-wrap justify-content-md-center">
            {
              data.allFeaturesJson && data.allFeaturesJson.edges.map((feature) => (
                <Feature key={feature.node.title} icon={feature.node.icon} title={feature.node.title} description={feature.node.description} />
              ))
            }
          </div>
        )}
      />
    </div>
  </section>
)

export default WhatWeDo
